import React from 'react';
import {
  Container,
  Grid,
  Header,
  List,
  Segment,
} from 'semantic-ui-react';
import Link from 'next/link';

const MainFooter = () => (
    <Segment
      inverted vertical
      style={{ backgroundColor: '#333333', padding: '3em 0em' }}
    >
      <Container>
        <Grid divided inverted stackable>
          <Grid.Row>
            <Grid.Column width={7}>
              <Header as="h4" inverted>
                업사이드 개발팀
              </Header>
              <p>
                업사이드 개발팀
                <br/><br/>
                © 업사이드 개발팀 All rights reserved
              </p>
            </Grid.Column>
            <Grid.Column width={3}>
              <Header inverted as="h4"/>
              <List link inverted>
                <Link href="/policy/terms">
                  <List.Item as="a">이용약관</List.Item>
                </Link>
                <Link href="/policy/privacy">
                  <List.Item as="a">개인정보 처리방침</List.Item>
                </Link>
                <Link href="/policy/operation">
                  <List.Item as="a">운영정책</List.Item>
                </Link>
                <Link href="/contract">
                  <List.Item as="a">문의</List.Item>
                </Link>
              </List>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
);

export default MainFooter;