import React, { Component } from 'react';
import { withRouter } from 'next/router';
import { WithRouterProps } from 'next/dist/client/with-router';
import LoginModal from 'src/containers/LoginModal';
import SocialRegistrationModal from 'src/containers/SocialRegistrationModal';
import CustomFooter from '../../components/MainFooter';
import MetaHead from '../../components/MetaHead';
import MainHeader from '../../components/MainHeader';
import { initGA, logPageView } from 'src/utils/analytics';
import { IStoreState } from '../reducers';
import { compose, Dispatch } from 'redux';
import {
  actionCreators,
  CheckEmailStartParams, CheckUsernameStartParams,
  LoginStartParams,
  RegisterStartParams,
  SocialRegisterStartParams,
} from '../reducers/userReducer';
import { connect } from 'react-redux';
import { wrapper } from '../reduxWrapper';
import { User, UserProfile } from '../models/User';

interface LayoutProps {
  currentUser: User | null;
  checkLoginStart: () => void;
  logout: () => void;
  // TODO: LoginModal에 보내고 싶은데, 지금 컨테이너 밑에 컨테이너 있으면 에러가 생긴다. 다음에 고치자
  loginLoading: boolean;
  registerLoading: boolean;
  loginStart: (params: LoginStartParams) => void;
  registerStart: (params: RegisterStartParams) => void;
  showLoginModal: () => void;
  hideLoginModal: () => void;
  loginModalVisible: boolean;
  // TODO: SocialLoginModal에 보내고 싶은데, 지금 컨테이너 밑에 컨테이너 있으면 에러가 생긴다. 다음에 고치자
  userProfile: UserProfile | null;
  socialRegistrationModalVisible: boolean;
  socialRegistrationProvider: string;
  hideSocialRegistrationModal: () => void;
  socialRegisterLoading: boolean;
  socialRegisterStart: (params: SocialRegisterStartParams) => void;
  checkEmailLoading: boolean;
  isAvailableEmail: boolean;
  checkUsernameLoading: boolean;
  isAvailableUsername: boolean;
  checkEmailStart: (params: CheckEmailStartParams) => void;
  checkUsernameStart: (params: CheckUsernameStartParams) => void;
}

class Layout extends Component<LayoutProps & WithRouterProps> {
  public componentDidMount() {
    // @ts-ignore
    if (!window.GA_INITIALIZED) {
      initGA();
      // @ts-ignore
      window.GA_INITIALIZED = true;
    }
    logPageView();

    const { currentUser, checkLoginStart } = this.props;
    if (!currentUser) {
      checkLoginStart();
    }
  }

  public render() {
    const {
      router,
      currentUser,
      logout,
      // TODO: LoginModal에 보내고 싶은데, 지금 컨테이너 밑에 컨테이너 있으면 에러가 생긴다. 다음에 고치자
      loginLoading,
      loginStart,
      registerStart,
      showLoginModal,
      hideLoginModal,
      loginModalVisible,
      // TODO: SocialRegistration Modal 용
      socialRegistrationModalVisible,
      socialRegistrationProvider,
      hideSocialRegistrationModal,
      userProfile,
      socialRegisterLoading,
      socialRegisterStart,
      checkEmailLoading,
      isAvailableEmail,
      checkUsernameLoading,
      isAvailableUsername,
      checkEmailStart,
      checkUsernameStart,
      registerLoading,
    } = this.props;

    const currentPath = router.pathname;
    return (
      <div style={{ backgroundColor: 'white' }}>
        <MetaHead/>
        <MainHeader
          showLoginModal={showLoginModal}
          currentPath={currentPath} currentUser={currentUser} logout={logout}
        />
        <div style={{ width: '100%', minHeight: 'calc(100vh - 287px)' }}>
          {this.props.children}
        </div>
        <CustomFooter/>
        <LoginModal
          loginLoading={loginLoading}
          registerLoading={registerLoading}
          loginStart={loginStart}
          currentUser={currentUser}
          visible={loginModalVisible}
          hideModal={hideLoginModal}
          registerStart={registerStart}
          checkEmailLoading={checkEmailLoading}
          isAvailableEmail={isAvailableEmail}
          checkUsernameLoading={checkUsernameLoading}
          isAvailableUsername={isAvailableUsername}
          checkEmailStart={checkEmailStart}
          checkUsernameStart={checkUsernameStart}
        />
        <SocialRegistrationModal
          visible={socialRegistrationModalVisible}
          hideModal={hideSocialRegistrationModal}
          socialRegistrationProvider={socialRegistrationProvider}
          userProfile={userProfile}
          socialRegisterLoading={socialRegisterLoading}
          socialRegisterStart={socialRegisterStart}
          checkEmailLoading={checkEmailLoading}
          isAvailableEmail={isAvailableEmail}
          checkUsernameLoading={checkUsernameLoading}
          isAvailableUsername={isAvailableUsername}
          checkEmailStart={checkEmailStart}
          checkUsernameStart={checkUsernameStart}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ user }: IStoreState) => ({
  currentUser: user.currentUser,
  // TODO: LoginModal에 보내고 싶은데, 지금 컨테이너 밑에 컨테이너 있으면 에러가 생긴다. 다음에 고치자
  loginLoading: user.loginLoading,
  registerLoading: user.registerLoading,
  userProfile: user.userProfile,
  // TODO: SociaRegistrationModal에 보내고 싶은데, 지금 컨테이너 밑에 컨테이너 있으면 에러가 생긴다. 다음에 고치자
  socialRegisterLoading: user.socialRegisterLoading,
  socialRegistrationProvider: user.socialRegistrationProvider,
  socialRegistrationModalVisible: user.socialRegistrationModalVisible,
  loginModalVisible: user.loginModalVisible,
  checkEmailLoading: user.checkEmailLoading,
  isAvailableEmail: user.isAvailableEmail,
  checkUsernameLoading: user.checkUsernameLoading,
  isAvailableUsername: user.isAvailableUsername,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  checkLoginStart: () => dispatch(actionCreators.checkLoginStart()),
  logout: () => dispatch(actionCreators.logout()),
  // TODO: LoginModal에 보내고 싶은데, 지금 컨테이너 밑에 컨테이너 있으면 에러가 생긴다. 다음에 고치자
  loginStart: (params: LoginStartParams) => dispatch(actionCreators.loginStart(params)),
  registerStart: (params: RegisterStartParams) => dispatch(actionCreators.registerStart(params)),
  socialRegisterStart: (params: SocialRegisterStartParams) => dispatch(
    actionCreators.socialRegisterStart(params),
  ),
  hideSocialRegistrationModal: () => dispatch(actionCreators.hideSocialRegistrationModal()),
  showLoginModal: () => dispatch(actionCreators.showLoginModal()),
  hideLoginModal: () => dispatch(actionCreators.hideLoginModal()),
  checkEmailStart: (params: CheckEmailStartParams) => dispatch(
    actionCreators.checkEmailStart(params),
  ),
  checkUsernameStart: (params: CheckUsernameStartParams) => dispatch(
    actionCreators.checkUsernameStart(params),
  ),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(wrapper.withRedux, withConnect, withRouter)(Layout);
