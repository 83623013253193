import { User } from '../models/User';

export default class UserUtils {
  public static getCurrentUserAvatar(currentUser: User): string {
    return (
      currentUser.profile_image || this.getAvatarImage(currentUser.username)
    );
  }

  public static getAvatarImage(avatarId?: string): string {
    return `https://api.adorable.io/avatars/40/${avatarId ?? Math.random()}`;
  }
}
