import React from 'react';
import 'semantic-ui-css/semantic.min.css';
import 'styles/global.scss';
import 'styles/slideshow.scss';
import 'react-toastify/dist/ReactToastify.css';
import 'cropperjs/dist/cropper.css';
import 'react-multi-carousel/lib/styles.css';
import App, { AppInitialProps } from 'next/app';
import * as Sentry from '@sentry/browser';
import { ToastContainer } from 'react-toastify';
import Layout from '../src/containers/Layout';
import { EnvChecker } from '../src/utils/EnvChecker';

let sentryDsn = 'https://379188808b1d4fefb0763f1582f4c5b1@o413118.ingest.sentry.io/5296128';
if (EnvChecker.isDevelopment()) {
  sentryDsn = 'https://8934dfe0690644efabe8dae2bcb9d71d@o413118.ingest.sentry.io/5296534';
}

// https://github.com/zeit/next.js/tree/canary/examples/with-sentry
Sentry.init({
  dsn: sentryDsn,
});

class MyApp extends App<AppInitialProps> {
  public componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });

      Sentry.captureException(error);
    });

    super.componentDidCatch(error, errorInfo);
  }

  render() {
    const { Component, pageProps } = this.props;

    return (
      <>
        <ToastContainer
          autoClose={2000}
          position="top-center"
        />
        <Layout initialState={{}} initialProps={{}}>
          <Component {...pageProps} />
        </Layout>
      </>
    );
  }
}

export default MyApp;
