import {
  CheckEmailStartParams,
  CheckUsernameStartParams,
  LoginStartParams,
  RegisterStartParams,
} from 'src/reducers/userReducer';
import * as React from 'react';
// @ts-ignore
import {OauthSender} from 'react-oauth-flow';
import {
  Button, Icon, Modal, Form, Tab, Divider,
} from 'semantic-ui-react';
import {withRouter} from 'next/router';
import {WithRouterProps} from 'next/dist/client/with-router';
import {Formik, Form as FormikForm, Field as FormikField} from 'formik';
import {ModalProps,} from '../HOC/withVisible';
import isClient from '../utils/isClient';
import {User} from '../models/User';
import {ObjectUtils} from '../utils/ObjectUtils';
import {StringUtils} from '../utils/StringUtils';
import {SpaceBetween} from "../components/Common/CommonStyled";

interface LoginModalProps extends ModalProps {
  loginLoading: boolean;
  loginStart: (params: LoginStartParams) => void;
  currentUser: User | null;
  registerLoading: boolean;
  registerStart: (params: RegisterStartParams) => void;
  checkEmailLoading: boolean;
  isAvailableEmail: boolean;
  checkUsernameLoading: boolean;
  isAvailableUsername: boolean;
  checkEmailStart: (params: CheckEmailStartParams) => void;
  checkUsernameStart: (params: CheckUsernameStartParams) => void;
}

interface SignInFormValues {
  email: string;
  password: string;
}

interface SignInFormErrors {
  email?: string;
  password?: string;
}

const signInInitialValues: SignInFormValues = {
  email: '',
  password: '',
};

interface SignUpFormValues {
  email: string;
  username: string;
  password: string;
  passwordCheck: string;
}

interface SignUpFormErrors {
  email?: string;
  username?: string;
  password?: string;
  passwordCheck?: string;
}

const signUpInitialValues: SignUpFormValues = {
  email: '',
  username: '',
  password: '',
  passwordCheck: '',
};


class LoginModal extends React.Component<LoginModalProps & WithRouterProps> {
  state = {
    isSignIn: true,
  }

  private switchMode = () => {
    this.setState({
      isSignIn: !this.state.isSignIn
    })
  }

  private getTitle = (isSignIn: boolean) => isSignIn ? "로그인하기" : "회원가입하기";

  private getForm = (isSignIn: boolean) => {
    if (isSignIn) {
      return this.getSignInFormik()
    } else {
      return this.getSignUpFormik();
    }
  }

  private getFooter = (isSignIn: boolean) => {
    if (isSignIn) {
      return (
        <SpaceBetween style={{marginTop: 48}}>
          <span>아직 의졸사 회원이 아니신가요?</span>
          <span onClick={this.switchMode} style={{
            color: "#00287b",
          }}>
              회원가입하기
            </span>
        </SpaceBetween>
      );
    } else {
      return (
        <SpaceBetween style={{marginTop: 48}}>
          <span>의졸사 회원이신가요?</span>
          <span onClick={this.switchMode} style={{
            color: "#00287b",
          }}>
              로그인하기
            </span>
        </SpaceBetween>
      );
    }
  }
  private getAuthFacebookCallback = () => {
    if (isClient()) {
      return `${window.location.origin}/auth/facebook/callback`;
    }
  };

  private getSignInFormik = () => {
    const {
      loginStart,
      loginLoading,
    } = this.props;

    return (
      <Form>
        <Formik
          enableReinitialize={true}
          initialValues={signInInitialValues}
          validate={(values) => {
            const errors: SignInFormErrors = {};
            if (!values.email) {
              errors.email = 'Required';
            } else if (
              !StringUtils.isEmail(values.email)
            ) {
              errors.email = 'Invalid email address';
            }
            if (values.password.length < 6) {
              errors.password = '비밀번호를 6자 이상으로 해주세염';
            }
            return errors;
          }}
          onSubmit={(values: SignInFormValues, {setSubmitting}) => {
            const {email, password} = values;
            loginStart({
              email,
              password,
            });
          }}
        >
          {({
              values,
              errors,
              touched,
            }) => (
            <FormikForm>
              <FormikField name="email">
                {({field, form, meta}: any) => (
                  <Form.Field>
                    <Form.Input
                      {...field}
                      label="이메일"
                      type="email"
                      placeholder="이메일을 입력해주세요"
                      error={touched.email && errors.email}
                    />
                  </Form.Field>
                )}
              </FormikField>
              <FormikField name="password">
                {({field, form, meta}: any) => (
                  <Form.Field>
                    <Form.Input
                      {...field}
                      label="비밀번호를 입력해주세요"
                      type="password"
                      placeholder="비밀번호"
                      error={touched.password && errors.password}
                    />
                  </Form.Field>
                )}
              </FormikField>
              <Form.Button
                loading={loginLoading}
                disabled={
                  !ObjectUtils.isEmpty(errors)
                }
                type="submit"
                fluid
                style={{
                  backgroundColor: "#00287b",
                  color: "white",
                  borderRadius: 22,
                }}
              >
                로그인
              </Form.Button>
              {/* TODO: 아직 구현안됨 */}
              {/*<div>*/}
              {/*  비밀번호 찾기*/}
              {/*</div>*/}
            </FormikForm>
          )}
        </Formik>
      </Form>
    );
  };

  private getSignUpFormik = () => {
    const {
      registerLoading,
      registerStart,
      checkEmailLoading,
      isAvailableEmail,
      checkUsernameLoading,
      isAvailableUsername,
      checkEmailStart,
      checkUsernameStart,
    } = this.props;

    return (
      <Form>
        <Formik
          enableReinitialize={true}
          initialValues={signUpInitialValues}
          validate={(values) => {
            const errors: SignUpFormErrors = {};
            if (!values.email) {
              errors.email = 'Required';
            } else if (
              !StringUtils.isEmail(values.email)
            ) {
              errors.email = 'Invalid email address';
            }

            if (values.password.length < 6) {
              errors.password = '비밀번호를 6자 이상으로 해주세염';
            }

            if (values.password !== values.passwordCheck) {
              errors.passwordCheck = '비밀번호가 일치하지 않습니다';
            }

            if (values.username.length < 2) {
              errors.username = '이름을 2자 이상으로 해주세요';
            }
            return errors;
          }}
          onSubmit={(values: SignUpFormValues, {setSubmitting}) => {
            const {email, username, password} = values;
            registerStart({
              email,
              password,
              username,
            });
          }}
        >
          {({
              values,
              errors,
              touched,
              handleBlur,
            }) => (
            <FormikForm>
              <FormikField name="email">
                {({field, form, meta}: any) => (
                  <Form.Field>
                    <Form.Input
                      {...field}
                      icon={!touched ? '' : (isAvailableEmail ? 'check circle' : 'ban')}
                      label="이메일"
                      type="email"
                      placeholder="이메일"
                      loading={checkEmailLoading}
                      error={
                        (touched.email && errors.email)
                        || (touched.email && !checkEmailLoading && !isAvailableEmail && '중복된 이메일입니다.')
                      }
                      onBlur={(e) => {
                        handleBlur(e);
                        checkEmailStart({
                          email: values.email,
                        });
                      }}
                    />
                  </Form.Field>
                )}
              </FormikField>
              <FormikField name="username">
                {({field, form, meta}: any) => (
                  <Form.Field>
                    <Form.Input
                      {...field}
                      icon={!touched ? '' : (isAvailableEmail ? 'check circle' : 'ban')}
                      label="닉네임"
                      placeholder="닉네임"
                      loading={checkUsernameLoading}
                      error={
                        (touched.username && errors.username)
                        || (touched.username && !checkUsernameLoading && !isAvailableUsername && '중복된 닉네임입니다.')
                      }
                      onBlur={(e) => {
                        handleBlur(e);
                        checkUsernameStart({
                          username: values.username,
                        });
                      }}
                    />
                  </Form.Field>
                )}
              </FormikField>
              <FormikField name="password">
                {({field, form, meta}: any) => (
                  <Form.Field>
                    <Form.Input
                      {...field}
                      label='비밀번호'
                      type="password"
                      placeholder="비밀번호"
                      error={touched.password && errors.password}
                    />
                  </Form.Field>
                )}
              </FormikField>
              <FormikField name="passwordCheck">
                {({field, form, meta}: any) => (
                  <Form.Input
                    {...field}
                    label='비밀번호 확인'
                    type="password"
                    placeholder="비밀번호 확인"
                    error={touched.passwordCheck && errors.passwordCheck}
                  />
                )}
              </FormikField>
              <Form.Button
                loading={
                  registerLoading
                  || checkEmailLoading
                  || checkUsernameLoading
                }
                disabled={
                  !ObjectUtils.isEmpty(errors)
                  || !isAvailableUsername
                  || !isAvailableEmail
                }
                type="submit"
                fluid
                style={{
                  backgroundColor: "#00287b",
                  color: "white",
                  borderRadius: 22,
                }}
              >
                회원가입
              </Form.Button>
            </FormikForm>
          )
          }
        </Formik>
      </Form>
    );
  };

  public render() {
    const {visible, hideModal, router} = this.props;
    const {isSignIn} = this.state;

    return (
      <Modal open={visible} onClose={hideModal} size="small">
        <div
          style={{
            textAlign: "center",
            position: "relative",
            fontSize: "1.4rem",
            margin: 16,
          }}>
          <Button
            style={{
              position: "absolute",
              left: 0,
              top: -4,
            }}
            circular basic icon="close" onClick={hideModal}
          />
          <p>{this.getTitle(isSignIn)}</p>
        </div>
        <div style={{
          textAlign: "center",
        }}>
          <OauthSender
            authorizeUrl="https://www.facebook.com/v5.0/dialog/oauth"
            clientId={process.env.NEXT_PUBLIC_REACT_APP_FACEBOOK_CLIENT_ID}
            redirectUri={this.getAuthFacebookCallback()}
            state={{asPath: router.asPath, pathname: router.pathname}}
            render={
              ({url}: any) => (
                <a href={url}>
                  <img
                    src="/btn_facebook.png"
                    style={{
                      width: 30,
                      height: 30
                    }}
                  />
                </a>
              )
            }
            args={{
              scope: 'public_profile,email',
            }}
          />
          <div>Facebook으로 로그인하기</div>
        </div>
        <Divider/>
        <Modal.Content>
          {this.getForm(isSignIn)}
          {this.getFooter(isSignIn)}
        </Modal.Content>
      </Modal>
    );
  }
}

export default withRouter(LoginModal);
