import {
  CheckEmailStartParams,
  CheckUsernameStartParams,
  SocialRegisterStartParams,
} from 'src/reducers/userReducer';
import * as React from 'react';
import {
  Button, Icon, Modal, Form,
} from 'semantic-ui-react';
import { withRouter } from 'next/router';
import { WithRouterProps } from 'next/dist/client/with-router';
import { Formik, Form as FormikForm, Field as FormikField } from 'formik';
import { ModalProps } from '../HOC/withVisible';
import { SOCIAL_PROVIDER_TO_STRING, UserProfile } from '../models/User';
import { StringUtils } from '../utils/StringUtils';
import { ObjectUtils } from '../utils/ObjectUtils';

interface SocialRegistrationModalProps extends ModalProps {
  socialRegistrationProvider: string;
  userProfile: UserProfile | null;
  socialRegisterLoading: boolean;
  socialRegisterStart: (params: SocialRegisterStartParams) => void;
  checkEmailLoading: boolean;
  isAvailableEmail: boolean;
  checkUsernameLoading: boolean;
  isAvailableUsername: boolean;
  checkEmailStart: (params: CheckEmailStartParams) => void;
  checkUsernameStart: (params: CheckUsernameStartParams) => void;
}

interface SocialRegistrationFormValues {
  email: string;
  username: string;
}

interface SocialRegistrationFormErrors {
  email?: string;
  username?: string;
}

const signUpInitialValues: SocialRegistrationFormValues = {
  email: '',
  username: '',
};

class SocialRegistrationModal extends React.Component<
  SocialRegistrationModalProps & WithRouterProps
  > {
  componentDidUpdate(
    prevProps: Readonly<
      SocialRegistrationModalProps & WithRouterProps
      >,
    prevState: Readonly<{}>,
    snapshot?: any,
  ): void {
    if (prevProps.visible === false && this.props.visible === true) {
      const { checkUsernameStart, checkEmailStart, userProfile } = this.props;
      if (!userProfile) {
        return;
      }
      checkEmailStart({
        email: userProfile.email,
      });
      checkUsernameStart({
        username: userProfile.name,
      });
    }
  }

  public render() {
    const { visible, hideModal } = this.props;
    const {
      socialRegistrationProvider,
      userProfile,
      socialRegisterLoading,
      socialRegisterStart,
      checkEmailLoading,
      isAvailableEmail,
      checkUsernameLoading,
      isAvailableUsername,
      checkEmailStart,
      checkUsernameStart,
    } = this.props;
    let signUpValues = signUpInitialValues;
    if (userProfile) {
      signUpValues = {
        email: userProfile.email,
        username: userProfile.name,
      };
    }

    return (
      <Modal open={visible} onClose={hideModal} size="small">
        <Modal.Header>{SOCIAL_PROVIDER_TO_STRING[socialRegistrationProvider]}으로 회원가입</Modal.Header>
        <Modal.Content>
          <Form>
            <Formik
              initialValues={signUpValues}
              validate={(values) => {
                const errors: SocialRegistrationFormErrors = {};
                if (!values.email) {
                  errors.email = 'Required';
                } else if (
                  !StringUtils.isEmail(values.email)
                ) {
                  errors.email = 'Invalid email address';
                }

                return errors;
              }}
              onSubmit={(values: SocialRegistrationFormValues, { setSubmitting }) => {
                const { email, username } = values;
                socialRegisterStart({
                  email,
                  username,
                });
              }}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
              }) => (
                <FormikForm>
                  <FormikField name="email">
                    {({ field, form, meta }: any) => (
                      <Form.Field>
                        <Form.Input
                          {...field}
                          icon={!touched ? '' : (isAvailableEmail ? 'check circle' : 'ban')}
                          label="이메일"
                          type="email"
                          placeholder="이메일을 입력해주세요"
                          loading={checkEmailLoading}
                          error={
                            (touched.email && errors.email)
                            || (touched.email && !checkEmailLoading && !isAvailableEmail && '중복된 이메일입니다.')
                          }
                          onBlur={(e) => {
                            handleBlur(e);
                            checkEmailStart({
                              email: values.email,
                            });
                          }}
                        />
                      </Form.Field>
                    )}
                  </FormikField>
                  <FormikField name="username">
                    {({ field, form, meta }: any) => (
                      <Form.Field>
                        <Form.Input
                          {...field}
                          icon={!touched ? '' : (isAvailableUsername ? 'check circle' : 'ban')}
                          label="닉네임"
                          placeholder="닉네임"
                          loading={checkUsernameLoading}
                          error={
                            (touched.username && errors.username)
                            || (touched.username && !checkUsernameLoading && !isAvailableUsername && '중복된 닉네임입니다.')
                          }
                          onBlur={(e) => {
                            handleBlur(e);
                            checkUsernameStart({
                              username: values.username,
                            });
                          }}
                        />
                      </Form.Field>
                    )}
                  </FormikField>
                  <Form.Button
                    loading={socialRegisterLoading || checkEmailLoading || checkUsernameLoading}
                    disabled={
                      !ObjectUtils.isEmpty(errors)
                      || !isAvailableUsername
                      || !isAvailableEmail
                    }
                    type="submit" color="blue"
                  >
                    회원가입
                  </Form.Button>
                </FormikForm>
              )}
            </Formik>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={hideModal} basic color="red">
            <Icon name="remove"/>닫기
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default withRouter(SocialRegistrationModal);
