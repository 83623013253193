import React, { Component } from 'react';
import {
  Button,
  Container,
  Header,
  Menu,
  Segment,
  Dropdown,
  Image,
} from 'semantic-ui-react';
import Link from 'next/link';
import { toast } from 'react-toastify';
import { User } from '../src/models/User';
import UserUtils from '../src/utils/UserUtils';

const menuItems = [
  { path: '/matches/1', name: '매치들' },
];

interface ResponsiveContainerProps {
  showLoginModal: () => void;
  logout: () => void;
  currentPath: string;
  currentUser: User | null;
}

class MainHeader extends Component<ResponsiveContainerProps> {
  private getMenuItems = (currentPath: string) => {
    let matchingPath = currentPath;
    const secondSlashIndex = currentPath.slice(1).indexOf('/');
    if (secondSlashIndex !== -1) {
      matchingPath = currentPath.slice(0, secondSlashIndex + 1);
    }

    return menuItems.map((menuItem, index) => (
      <Link key={`menu_item_${index}`} href={menuItem.path}>
        <Menu.Item
          key={`menu_item_${index}`}
          name={menuItem.name}
          active={matchingPath === menuItem.path}
        />
      </Link>
    ));
  };

  private getButtons = () => {
    const { showLoginModal, currentUser, logout } = this.props;
    if (currentUser) {
      const options = [
        {
          key: 'settings', value: 'test', text: '설정', icon: 'settings',
        },
        {
          key: 'sign-out', value: 'sign-out', text: '로그아웃', icon: 'sign out',
        },
      ];

      return (
        <Dropdown
          onChange={
            (e, { value }) => {
              if (value === 'sign-out') {
                logout();
              } else {
                toast('아직 구현 중입니다');
              }
            }
          }
          selectOnBlur={false}
          trigger={
            <span>
                <Image
                  avatar
                  src={UserUtils.getCurrentUserAvatar(currentUser)}
                />
              {currentUser.username}
              </span>
          }
          options={options}
          pointing='top right'
          icon={null}
          value={null}
        />
      );
    }
    return (
      <Button inverted onClick={showLoginModal}>로그인</Button>
    );
  };

  render() {
    const { currentPath } = this.props;
    return (
      <Segment
        inverted
        textAlign='center'
        style={{ padding: '0.5em 0em 2px', backgroundColor: '#FF0099' }}
        vertical
      >
        <Container style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}>
          <Header size='medium' inverted style={{ margin: '0px' }}>
            <Link href="/">
              <a style={{
                height: '100%',
                flexGrow: 1,
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}>
                <img
                  src="/logo.png" alt="Big logo white"
                  style={{ height: 56, width: 'auto' }}
                />
              </a>
            </Link>
          </Header>
          <div style={{
            height: '100%',
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}>
            <Button icon='instagram' inverted style={{ marginRight: '0.5em' }}
                    onClick={() => window.open('https://www.instagram.com/dogsandcats_match/', '_blank')}/>
            {/*<Button icon='facebook' inverted style={{ marginRight: '0.5em' }}*/}
            {/*        onClick={() => window.open('https://www.facebook.com/%EC%9D%98%EC%A1%B8%EC%82%AC-108765284251270', '_blank')}/>*/}
            {this.getButtons()}
          </div>
        </Container>
        <Menu inverted pointing secondary size='large' style={{ borderWidth: '0px' }}>
          <Container>
            {this.getMenuItems(currentPath)}
          </Container>
        </Menu>
      </Segment>
    );
  }
}

export default MainHeader;
