import React from 'react';
import Head from 'next/head';
import { NextSeo } from 'next-seo';

const MetaHead = () => (
    <>
      <NextSeo
        title='니가개냥'
        description="니가개냥 앱입니다"
        openGraph={{
          url: 'https://catsanddogs.cf',
          title: '반려동물 사진 대결 서비스 - 니가개냥',
          description: '지금 니가개냥에서 매치에 참여해보세요!',
          images: [
            {
              url: '/cover_image.png',
              width: 800,
              height: 600,
              alt: 'Main Logo',
            },
          ],
          type: 'website',
          site_name: '니가개냥',
        }}
        twitter={{
          handle: '@handle',
          site: '@site',
          cardType: 'summary_large_image',
        }}
        facebook={{
          appId: process.env.NEXT_PUBLIC_REACT_APP_FACEBOOK_CLIENT_ID,
        }}
      />
      <Head>
        <script data-ad-client="ca-pub-2077503952644064" async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"/>
        <meta charSet="utf-8"/>
        <meta name="naver-site-verification" content="16d2e9e286da98a361241514294d2363100649f1"/>
        <meta name="google-site-verification" content="l0t6N1sMUrEK1uwhq7nX4PwifwZ3Vae-oAv4YJXbxBA"/>
        <meta name="viewport" content="initial-scale=1.0, width=device-width"/>
      </Head>
    </>
);

export default MetaHead;